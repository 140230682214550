import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ViewAd = (props) => {
  const [ad, setAd] = useState({});

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://ads-management-web-application.onrender.com/ads/ad/view/${id}`)
      .then((res) => {
        setAd(res.data);
      })
      .catch((err) => {
        console.log('Unsucessfully');
      });
  }, [id]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"250px" }}>
      <div style={{ width: '80%', backgroundColor: '#f5f5f5', padding: '25px', borderRadius: '10px', boxShadow: '0px 5px 10px #d3d3d3' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>View Ad</h1>
        <br></br>
        <table style={{ width: '100%', borderCollapse: 'collapse', }}>
          <tbody>
            <tr><td style={{ padding: '10px', backgroundColor: '#ffffff' }}>
              <img src={typeof (ad.image1) !== 'undefined' ? require(`E:/Pro Work/MainProject01-1/frontend/src/components/Admin/image/${ad.image1}`) : 'Error'} style={{ width: "100px", height: "100px", margin: "auto" }} alt="AD1" />
              <img src={typeof (ad.image2) !== 'undefined' ? require(`E:/Pro Work/MainProject01-1/frontend/src/components/Admin/image/${ad.image1}`) : 'Error'} style={{ width: "100px", height: "100px", margin: "auto" }} alt="AD2" />
              <img src={typeof (ad.image3) !== 'undefined' ? require(`E:/Pro Work/MainProject01-1/frontend/src/components/Admin/image/${ad.image1}`) : 'Error'} style={{ width: "100px", height: "100px", margin: "auto" }} alt="AD3" />
              <img src={typeof (ad.image4) !== 'undefined' ? require(`E:/Pro Work/MainProject01-1/frontend/src/components/Admin/image/${ad.image1}`) : 'Error'} style={{ width: "100px", height: "100px", margin: "auto" }} alt="AD4" />
              <img src={typeof (ad.image5) !== 'undefined' ? require(`E:/Pro Work/MainProject01-1/frontend/src/components/Admin/image/${ad.image1}`) : 'Error'} style={{ width: "100px", height: "100px", margin: "auto" }} alt="AD5" />
              </td></tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>condition</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.condition}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>brand</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.brand}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>model</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.model}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>trim</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.trim}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>yearofmanufature</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.yearofmanufature}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>mileage</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.mileage}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>enginecapacity</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.enginecapacity}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>fueltype</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.fueltype}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>transmission</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.transmission}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>bodytype</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.bodytype}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>Description</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.desc}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>price</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.price}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>negotiable</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.negotiable}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewAd;
