import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../userManagement/context/UserContext";
import Login from "../userManagement/authentication/Login";
import Register from "../userManagement/authentication/Register";
import Sidebar from "../layout/Sidebar";
// import TopProfile from "../userManagement/user/TopProfile";
import Profile from "../userManagement/user/Profile";
import AdminDashboard from "../Admin/AdminDashboard";
import ClientDashboard from "../Client/ClientDashboard";
import AddAd from "../Admin/AddAd";
import UserList from "../userManagement/user/UserList";
import UpdateAd from "../Admin/UpdateAd";
import ViewAd from "../Admin/ViewAd";
import ViewAdsByAdmin from "../Admin/ViewAdsByAdmin";
import ViewAdsByClient from "../Admin/ViewAdsByClient";
import ViewAds from "../Admin/ViewAds";
import Verify from "../userManagement/authentication/Verify";
import UpdateUser from "../userManagement/user/UpdateUser";
import LoginUi from "../UI/LoginUi";
import RegisterUi from "../UI/RegisterUi";
import AdminDashboardUi from "../UI/AdminDashboardUi";
import NavBarUi from "../UI/NavBarUi";
import { Navbar } from "react-bootstrap";
import SideBarUi from "../UI/SideBarUi";
import TopProfile from "../userManagement/user/TopProfile";
import UpdateProfile from "../userManagement/user/UpdateProfile";
import ViewPendingADs from "../Admin/ViewPendingADs";
import ViewRejectedADs from "../Admin/ViewRejectedADs";
import ViewPausedADs from "../Admin/ViewPausedADs";
import ViewResumedADs from "../Admin/ViewResumedADs";
import ADReport from "../Admin/ADReport";
import WishedList from "../Admin/WishedList";

function Router() {
  /* Getting the userType from the AuthContext. */
  const { userType } = useContext(AuthContext);
  console.log(userType);
  return (
    <BrowserRouter>
    {/* {<NavBarUi />} */}
    {/* {<SideBarUi/>} */}
    {userType == 'Admin' && 'Customer' && <NavBarUi />}
      {userType == 'Admin' && <Sidebar />}
      <div className="App">
        {/* {userType !== null && <TopProfile />} */}
        <Routes>
        <Route path="/verify/:id/:token" element={<Verify />} />
          {userType === null && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/addad" element={<AddAd/>}/>
              <Route path="/loginul" element={<LoginUi/>}/>
              <Route path="/registerui" element={<RegisterUi/>}/>
              <Route path="/admindashboardui" element={<AdminDashboardUi/>}/>
              <Route path="/navbar" element={<NavBarUi/>}/>
             
           
            </>
          )}
          {userType === "Admin" && (
            <>
             
              <Route exact path="/" element={<AdminDashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/addad" element={<AddAd/>}/>
              <Route path="/users" element={<UserList />} />
              <Route path="/viewads-admin" element={<ViewAdsByAdmin/>}/>
              <Route path="/updatead/:id" element={<UpdateAd/>}/>
              <Route exact path="/view/:id" element={<ViewAd />} />
              <Route path="/viewads" element={<ViewAds/>}/>
              <Route path="/users/update" element={<UpdateUser />} />
              <Route path="/admindashboardui" element={<AdminDashboardUi/>}/>
              <Route path="/sidebarui" element={<SideBarUi/>}/>
              <Route path="/viewads-client" element={<ViewAdsByClient/>}/>
              <Route path="/viewpending-client" element={<ViewPendingADs/>}/>
              <Route path="/viewrejected-client" element={<ViewRejectedADs/>}/>
              <Route path="/viewpaused-client" element={<ViewPausedADs/>}/>
              <Route path="/viewresumed-client" element={<ViewResumedADs/>}/>
              <Route path="/user/update" element={<UpdateProfile />} />
              <Route path="/ad/adreport" element={<ADReport />} />
              <Route path="/viewwished-client" element={<WishedList/>}/>
            </>
          )}

{userType === "Client" && (
            <>
             
              <Route exact path="/" element={<ClientDashboard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/viewads-client" element={<ViewAdsByClient/>}/>
              <Route path="/addad" element={<AddAd/>}/>
              <Route path="/updatead/:id" element={<UpdateAd/>}/>
              <Route exact path="/view/:id" element={<ViewAd />} />
              <Route path="/viewads" element={<ViewAds/>}/>
            </>
          )}

          <Route exact path="*" element={<Login />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default Router;
