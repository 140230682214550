import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Card, CardGroup } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function ADReport() {
  const [getaddata, setAddata] = useState([]);
  const [reportDate, setReportDate] = useState("");
  const componentRef = useRef();
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "ADs-Report",
  });

  const handleBack = () => {
    navigate("/");
  };

  const getdata = async () => {
    const res = await axios.get("https://ads-management-web-application.onrender.com/ads/ad/view-admin");
    console.log(res.data);

    if (res.status === 422 || !res) {
      console.log("error ");
    } else {
      setAddata(res.data.getaddata);
      setReportDate(new Date().toLocaleDateString()); // Set report generation date to current date
      console.log("get data");
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var dataList = getaddata.map((element, index) => {
    return (
      <tr key={index}>
        <td>{element._id}1</td>
        <td>{element.name}1</td>
        <td>{element.desc}1</td>
      </tr>
    );
  });

  return (
    <div className="container" style={{ marginLeft: "250px" }}>
      <div>
        <Button onClick={handlePrint}>
          <PrintIcon />
        </Button>
      </div>
      <div
        ref={componentRef}
        style={{ width: "100%", height: window.innerHeight }}
      >
        <CardGroup>
          <Card>
            <Card.Body>
              <Card.Title style={{ textAlign: "center" }}>
                AD Report
              </Card.Title>
              <div style={{ marginBottom: "20px" }}>
                <b>Report Date:</b> {reportDate}
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th scope="col">
                        <b>AD ID</b>
                      </th>
                      <th scope="col">
                        <b>AD Name</b>
                      </th>
                      <th scope="col">
                        <b>AD Description</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{dataList}</tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
    </div>
  );
}

export default ADReport;
