import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import './LoginUiCss.css'


const LoginUi = () => {

  const bull = (
    <Box
      component="span"
      boxShadow={4}
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  return (
    <div className='login-container1'>

      <Card className='login-container2' sx={{ minWidth: 500, height: 450, backgroundColor: "rgba(228, 225, 211, 12)"}}>

      <div className='login-left-content'><CardContent>
        <div className='login-left-logo-text'>Logo</div>
      </CardContent>
      </div>
      <hr/>

      <div className='login-right-content'>
      <div className='login-right-heading'>Login</div>
        <div className='login-right-form'>
          <div>
        <TextField
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          maxRows={4}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Password"
          multiline
          maxRows={4}
        />
        </div>
        <div className='login-right-buttons'>
      <Button className='login-right-loginbutton' variant="outlined">Login</Button>
        </div>
        <div className='login-right-fptext'><span>Forget Password ?</span></div>
        </div>
        <div className='login-rignt-registertext'><span>Create Your Account</span></div>
      </div>

    </Card>
    </div>
  )
}

export default LoginUi
