import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

function ViewPendingADs() {
    const [getaddata, setAddata] = useState([]);

  const getdata = async () => {
    const res = await axios.get("https://ads-management-web-application.onrender.com/ads/ad/viewpending-client");
    console.log(res.data);

    if (res.status === 422 || !res) {
      console.log("error ");
    } else {
      setAddata(res.data.getaddata);

      console.log("get data");
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pausedAd = async (id) => {
    const result = await axios.put(
      `https://ads-management-web-application.onrender.com/ads/ad/update-status-client/${id}`,
      { quickstatus: "Paused"}
    );
    console.log("aaaa")

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Paused Ad Successfully");
      console.log("Paused Ad Successfully");
      getdata();
    }
  };

  

  


  return (
    <div className="container" style={{marginLeft:"250px"}}>
      <div>
        <table>
          <thead>
            <tr>
            <th scope="col">
                <b>AD ID</b>
              </th>
              <th scope="col">
                <b>AD Name</b>
              </th>
              <th scope="col">
                <b>AD Description</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {getaddata.map((element, id) => {
              return (
                <tr>
                    <td><Link to={`/view/${element._id}`}>{id}</Link></td>
                  <td>{element.name}</td>
                  <td>{element.desc}</td>
                  <td>{element.status}</td>
                  <td>{element.quickstatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ViewPendingADs;
