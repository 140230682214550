import React, { useEffect, useState } from "react";
import axios from "axios";
import logo from "./Profile-art.png";

export default function TopProfile() {
  const [userData, setUserData] = useState("");

  async function getData() {
    try {
      const result = await axios.get("https://ads-management-web-application.onrender.com/user/profile");
      if (result.data.dob) {
        const dobEdited = new Date(result.data.dob)
          .toISOString()
          .substring(0, 10);
        result.data.dob = dobEdited;
      }
      setUserData(result.data);
    } catch (err) {
      console.error(err);
    }
  }

  /* Calling the getData function when the component is mounted. */
  useEffect(() => {
    getData();
  }, []);
  return (
      <div style={{marginLeft: "470px"}}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div>
          <h4 style={{fontSize: "23px", margin: "10px"}}>Welcome {userData.firstName}</h4>
          </div>
          <a href="/profile">
          <div style={{display: "flex", flexDirection: "column", marginLeft: "10px", fontSize: "100px"}}>
          {/* <h5 style={{fontSize: "13px", textDecoration: "none"}}>{userData.userType}</h5> */}
          
        <img src={logo} width={40} height={40} alt="profile" title={userData.userType} />
        
        </div>
        </a>
        </div>
      </div>
  );
}
