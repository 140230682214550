import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import './AddAd.css'

const AddAd = (props) => {
  const [condition, setCondition] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [trim, setTrim] = useState("");
  const [yearofmanufature, setYearOfManufature] = useState("");
  const [mileage, setMileage] = useState("");
  const [enginecapacity, setEngineCapacity] = useState("");
  const [fueltype, setFuelType] = useState("");
  const [transmission, setTransmission] = useState("");
  const [bodytype, setBodyType] = useState("");
  const [desc, setDesc] = useState("");
  const [price, setPrice] = useState("");
  const [negotiable, setNegotiable] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("condition", condition);
    formData.append("brand", brand);
    formData.append("model", model);
    formData.append("trim", trim);
    formData.append("yearofmanufature", yearofmanufature);
    formData.append("mileage", mileage);
    formData.append("enginecapacity", enginecapacity);
    formData.append("fueltype", fueltype);
    formData.append("transmission", transmission);
    formData.append("bodytype", bodytype);
    formData.append("desc", desc);
    formData.append("price", price);
    formData.append("negotiable", negotiable);
    formData.append("image1", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("image4", image4);
    formData.append("image5", image5);
    try {
      const result = await axios.post("https://ads-management-web-application.onrender.com/ads/ad/new", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (result?.status === 201) {
        alert("Success");
        navigate("/");
      }
    } catch (err) {
      console.error(err?.response?.data?.errorMessage);
      alert(err?.response?.data?.errorMessage);
    }
  };

  const handleFileChange1 = (e) => {
    setImage1(e.target.files[0]);
  };

  const handleFileChange2 = (e) => {
    setImage2(e.target.files[0]);
  };

  const handleFileChange3 = (e) => {
    setImage3(e.target.files[0]);
  };

  const handleFileChange4 = (e) => {
    setImage4(e.target.files[0]);
  };

  const handleFileChange5 = (e) => {
    setImage5(e.target.files[0]);
  };

  const resetForm = (e) => {
    // setName("");
    // setDesc("");
    // setImage1(null);
    // setImage2(null);
    // setImage3(null);
    // setImage4(null);
    // setImage5(null);
  };

  return (
    <div className="addad-contatiner">
      <div className="addad-form">
      <Form onSubmit={handleSubmit} style={{ width: "400px" }}>
        <div className="addad-form-left">
        <Form.Group controlId="condition">
          <Form.Label>condition</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter condition"
            required
            onChange={(e) => setCondition(e.target.value)}
            value={condition}
          />
        </Form.Group>

        
        <Form.Group controlId="brand">
          <Form.Label>brand</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter brand"
            required
            onChange={(e) => setBrand(e.target.value)}
            value={brand}
          />
        </Form.Group>

       
        <Form.Group controlId="name">
          <Form.Label>model</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter model"
            required
            onChange={(e) => setModel(e.target.value)}
            value={model}
          />
        </Form.Group>

      
        <Form.Group controlId="trim">
          <Form.Label>trim</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter trim"
            required
            onChange={(e) => setTrim(e.target.value)}
            value={trim}
          />
        </Form.Group>

        
        <Form.Group controlId="yearofmanufature">
          <Form.Label>yearofmanufature</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter yearofmanufature"
            required
            onChange={(e) => setYearOfManufature(e.target.value)}
            value={yearofmanufature}
          />
        </Form.Group>

      
        <Form.Group controlId="mileage">
          <Form.Label>mileage</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter mileage"
            required
            onChange={(e) => setMileage(e.target.value)}
            value={mileage}
          />
        </Form.Group>

        </div>
       <div className="addad-form-right">
        <Form.Group controlId="enginecapacity">
          <Form.Label>enginecapacity</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter enginecapacity"
            required
            onChange={(e) => setEngineCapacity(e.target.value)}
            value={enginecapacity}
          />
        </Form.Group>

        <Form.Group controlId="fueltype">
          <Form.Label>fueltype</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter fueltype"
            required
            onChange={(e) => setFuelType(e.target.value)}
            value={fueltype}
          />
        </Form.Group>

       
        <Form.Group controlId="transmission">
          <Form.Label>transmission</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter transmission"
            required
            onChange={(e) => setTransmission(e.target.value)}
            value={transmission}
          />
        </Form.Group>

        <Form.Group controlId="bodytype">
          <Form.Label>bodytype</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter bodytype"
            required
            onChange={(e) => setBodyType(e.target.value)}
            value={bodytype}
          />
        </Form.Group>

        <Form.Group controlId="desc">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            required
            onChange={(e) => setDesc(e.target.value)}
            value={desc}
            maxlength="5000" // added maxlength attribute
          />
        </Form.Group>

        <Form.Group controlId="price">
          <Form.Label>price</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter price"
            required
            onChange={(e) => setPrice(e.target.value)}
            value={price}
          />
        </Form.Group>

        <Form.Group controlId="negotiable">
  <Form.Label>Negotiable</Form.Label>
  <div key={`inline-radio`} className="mb-3">
    <Form.Check 
      inline 
      label="Yes" 
      name="negotiable" 
      type="radio"
      id={`inline-radio-1`}
      checked={negotiable === "Yes"}
      onChange={(e) => setNegotiable(e.target.value)}
      value="Yes"
    />
    <Form.Check 
      inline 
      label="No" 
      name="negotiable" 
      type="radio"
      id={`inline-radio-2`}
      checked={negotiable === "No"}
      onChange={(e) => setNegotiable(e.target.value)}
      value="No"
    />
  </div>
</Form.Group>

        </div>
        <div className="addad-form-middle">

        <Form.Group controlId="image1">
          <Form.Label>Image1</Form.Label>
          <Form.Control type="file" onChange={handleFileChange1} />
        </Form.Group>

        <Form.Group controlId="image2">
          <Form.Label>Image2</Form.Label>
          <Form.Control type="file" onChange={handleFileChange2} />
        </Form.Group>

        <Form.Group controlId="image3">
          <Form.Label>Image3</Form.Label>
          <Form.Control type="file" onChange={handleFileChange3} />
        </Form.Group>

        <Form.Group controlId="image4">
          <Form.Label>Image4</Form.Label>
          <Form.Control type="file" onChange={handleFileChange4} />
        </Form.Group>

        <Form.Group controlId="image5">
          <Form.Label>Image5</Form.Label>
          <Form.Control type="file" onChange={handleFileChange5} />
        </Form.Group>
        </div>
        <div className="addad-button">
          <Button
            onClick={resetForm}
            variant="secondary"
            size="lg"
            style={{ width: "45%", margin: "5px" }}
          >
            Reset
          </Button>

          <Button
            variant="primary"
            size="lg"
            type="submit"
            style={{ width: "45%", margin: "5px" }}
          >
            Add New AD
          </Button>
        </div>
      </Form>
      </div>
    </div>
  );
};

export default AddAd;
