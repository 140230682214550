import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const UpdateAd = () => {
  const [ad, setAd] = useState({
    name: '',
    desc: ''
  });

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://ads-management-web-application.onrender.com/ads/ad/view/${id}`)
      .then((res) => {
        setAd({
          name: res.data.name,
          desc: res.data.desc
        });
      })
      .catch((err) => {
        console.log('Unsucessfully');
      });
  }, [id]);

  const onChange = (e) => {
    setAd({ ...ad, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: ad.name,
      desc: ad.desc
    };

    axios
      .put(`https://ads-management-web-application.onrender.com/ads/ad/update/${id}`, data)
      .then((res) => {
        navigate(`/viewads-client`);
      })
      .catch((err) => {
        console.log('Error in UpdateBookInfo!');
      });
  };

  return (
    <div className="container" style={{marginLeft:"250px"}}>
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">Update Ad</h1>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
            <input
              type='text'
              name='name'
              id='name'
              value={ad.name}
              onChange={onChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="desc" className="block text-gray-700 font-bold mb-2">Description</label>
            <input
              type='text'
              name='desc'
              id='desc'
              value={ad.desc}
              onChange={onChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="flex justify-center">
            <button
              type='submit'
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateAd;
