import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import './RegisterUiCss.css'


const LoginUi = () => {

  const bull = (
    <Box
      component="span"
      boxShadow={4}
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  return (
    <div className='register-container1'>

      <Card className='register-container2' sx={{ minWidth: 500, height: 700, backgroundColor: "rgba(228, 225, 211, 12)"}}>

      <div className='register-left-content'><CardContent>
        <div className='register-left-logo-text'>Register</div>
      </CardContent>
      </div>
      <hr/>

      <div className='register-right-content'>
      <div className='register-right-heading'>Register</div>
        <div className='register-right-form'>
          <div className='register-right-form-parta'>
          <div>
        <TextField
          id="outlined-multiline-flexible"
          label="First Name"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Date of Birth"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Password"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        </div>
        <div className='register-right-form-partb'>
          <div>
        <TextField
          id="outlined-multiline-flexible"
          label="Last Name"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Contact No"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Country"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        <div>
        <br/>
        <TextField
          id="outlined-multiline-flexible"
          label="Confirm Password"
          multiline
          maxRows={4}
          style={{width:"200px"}}
        />
        </div>
        </div>
        </div>
        <div className='register-right-buttons'>
      <Button className='register-right-loginbutton' variant="outlined">Register</Button>
        </div>
        <div className='register-rignt-registertext'><span>Login</span></div>
      </div>

    </Card>
    </div>
  )
}

export default LoginUi
