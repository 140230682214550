import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card, Row, Col } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";

function ViewAds() {
  const [getaddata, setAddata] = useState([]);

  const getdata = async () => {
    const res = await axios.get("https://ads-management-web-application.onrender.com/ads/ad/view");
    console.log(res.data);

    if (res.status === 422 || !res) {
      console.log("error ");
    } else {
      setAddata(res.data.getaddata);

      console.log("get data");
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wishedad = async (id) => {
    const result = await axios.put(
      `https://ads-management-web-application.onrender.com/ads/ad/update-wishedstatus/${id}`,
      { wished: "Wished" }
    );
    console.log("aaaa");
  
    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Wished added successfully")
      getdata();
    }
  };
  
  

  return (
    <div style={{marginLeft:"250px"}}>
      <div><h1>Vehicle ADs</h1></div>
    <Row xs={1} md={2} lg={3} className="g-4" style={{ margin: "0 auto", marginTop: "50px"}}>
      {getaddata.map((element, id) => (
        <Col key={id}>
          <Card>
            
          <Link to={`/view/${element._id}`} className="card-link">
  <Card className="my-3 shadow-sm">
    <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
      <Card.Img
        variant="top"
        src={typeof (element.image1) !== 'undefined' ? require(`E:/Pro Work/MainProject01-1/frontend/src/components/Admin/image/${element.image1}`) : 'Error'}
        alt={element.name}
        className="img-fluid"
      />
    </div>
    <Card.Body>
      <Card.Title className="mb-3">{element.name}</Card.Title>
      <Card.Text className="mb-2">{element.desc}</Card.Text>
      <Card.Subtitle className="text-muted">Posted by {element.user.firstName}</Card.Subtitle>
    </Card.Body>
  </Card>
</Link>

  <FaHeart onClick={() => wishedad(element._id)} />
          </Card>
        </Col>
      ))}
    </Row>
    </div>
  );
}

export default ViewAds;
