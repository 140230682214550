import React, {useState} from 'react'
import { CNavbar, 
    CContainer, 
    CNavbarBrand, 
    CNavbarToggler, 
    CCollapse, 
    CNavbarNav, 
    CNavItem, 
    CNavLink,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CDropdownDivider,
    CForm,
    CFormInput,
    CButton } from '@coreui/react';
    import '@coreui/coreui/dist/css/coreui.min.css'
import TopProfile from '../userManagement/user/TopProfile';
import LogOut from '../userManagement/authentication/Logout';

const NavBarUi = () => {

    const [visible, setVisible] = useState(false)

  return (
    <div style={{marginLeft: "240px"}}>
  <>
    <CNavbar expand="lg" colorScheme="light" className="bg-light">
      <CContainer fluid>
        <CNavbarBrand href="/clientdashboard">My Dashboard</CNavbarBrand>
        <CNavbarToggler onClick={() => setVisible(!visible)} />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav>
          <CNavItem>
              <CNavLink href="addad">New Ad</CNavLink>
            </CNavItem>
            <CNavItem>
            <CDropdown variant="nav-item" popper={false}>
              <CDropdownToggle color="secondary">Ads Catogery</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="/viewads">Vehicles</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="#">Phones</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="#">Properties</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            </CNavItem>
            <CDropdown variant="nav-item" popper={false}>
              <CDropdownToggle color="secondary">My Ads</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem href="/viewads-client">My All Ads</CDropdownItem>
                <CDropdownDivider />
                <CDropdownItem href="/viewpending-client">My Under Review Ads</CDropdownItem>
                <CDropdownDivider />
              <CDropdownItem href="/viewrejected-client">My Rejected Ads</CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem href="/viewpaused-client">My Paused Ads</CDropdownItem>
              <CDropdownDivider />
              <CDropdownItem href="/viewresumed-client">My Resumed Ads</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CNavbarNav>
          {/* <CForm className="d-flex">
            <CFormInput type="search" className="me-2" placeholder="Search" />
            <CButton type="submit" color="success" variant="outline">
              Search
            </CButton>
          </CForm> */}
          <TopProfile/>
          <LogOut/>
        </CCollapse>
      </CContainer>
    </CNavbar>
  </>
    </div>
  )
}

export default NavBarUi
