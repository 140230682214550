import React from 'react'
import { CChart } from '@coreui/react-chartjs'
import './AdminDashboardUi.css'

const AdminDashboardUi = () => {
  return (
    <div className='admin-dashoard-container'>
        <div className='admin-dashboard-left-side'>
            <div className='ad-ls-parta'>
                <h1>All Users</h1>
        <CChart
  type="doughnut"
  data={{
    labels: ['Admin', 'Customer'],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
        data: [40, 20],
      },
    ],
  }}
/>
</div>
<div className='ad-ls-partb'>
    <h1>Monthly Registered Users</h1>
        <CChart
  type="bar"
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'No of Users',
        backgroundColor: '#f87979',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 25, 34, 52],
      },
    ],
  }}
  labels="months"
/>
</div>
        </div>
        <div className='admin-dashboard-right-side'>
        <div className='ad-rs-parta'>
            <h1>All Ads</h1>
        <CChart
  type="doughnut"
  data={{
    labels: ['Approved', 'Rejected', 'Pending', 'Paused', 'Resumed'],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#DD1B17'],
        data: [40, 20, 80, 10],
      },
    ],
  }}
/>
</div>
            <div className='ad-rs-partb'>
                <h1>Monthly Ads</h1>
        <CChart
  type="bar"
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'No of Ads',
        backgroundColor: '#f87979',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 25, 34, 52],
      },
    ],
  }}
  labels="months"
/>
</div>
        </div>
    </div>
  )
}

export default AdminDashboardUi
